<div class="ontario-alert" [ngClass]="setupAlert()" tabindex="0" id="alertPanel">
    <div class="ontario-alert__header">
        <div class="ontario-alert__header-icon">
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use [attr.href]="alertIcon"></use>
            </svg>
        </div>
        <h2 class="ontario-alert__header-title ontario-h4">{{alertTitle}}</h2>
    </div>
    <div class="ontario-alert__body">
        <p>{{alertMessage}}</p>
        @for (error of alertErrorList; track $index) {
        <ul>
            <li><a id="errorDetail" (click)="navigateToValidation(error)" tabindex="0"
                    (keydown)="onRowKeyDown($event, error)">{{error | errorname}} </a></li>
        </ul>
        }
    </div>
</div>