import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appInputFocus]',
  standalone: true
})
export class InputFocusDirective implements OnInit {

  @Input() afterParentViewInit = false;
  constructor(private renderer: Renderer2, public elementRef: ElementRef) { }

  ngOnInit() {
    if (this.afterParentViewInit) {
      this.renderer.selectRootElement(this.elementRef.nativeElement).focus();
    }
  }

}
