export const environment = {
    production: false,
    officeHourType: 'rpd',
    msalConfig: {
        auth: {
            clientId: '2316fca0-274e-4936-be1a-f595d618f031',
            authority: 'https://login.microsoftonline.com/cddc1229-ac2a-4b97-b78a-0e5cacb5865c',
        },
    },
    apiConfig: {
        server: {
            APIEndpoint: 'https://api.stage.rpd.mto.gov.on.ca',
            APIScopes: 'api://2316fca0-274e-4936-be1a-f595d618f031/access.dima.rpd',
        },
        graph: {
            scopes: 'user.read',
            uri: 'https://graph.microsoft.com/v1.0/me',
        }
    },
};