import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private httpClient: HttpClient) { }

  download(email: string): Observable<Blob> {
    const url = `${environment.apiConfig.server.APIEndpoint}/rpd/download?requestor=${encodeURIComponent(email)}&dataStore=OPP-Request`;

    return this.httpClient.get(url, { responseType: 'blob' });
  }

  downloadCheck(email: string) {
    const url = `${environment.apiConfig.server.APIEndpoint}/rpd/checkDownload?requestor=${encodeURIComponent(email)}&dataStore=OPP-Request`;

    return this.httpClient.get(url);
  }

}
