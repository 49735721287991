import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "errorname", standalone: true })
export class ErrorNamePipe implements PipeTransform {

    errorMap = {
        'radioButtons.required': 'Request must be made under s. 42(1)(g) of the Freedom of Information and Protection of Privacy Act.',
        'fileNumberInput.required': 'You must enter the File Number or Occurrence Number.',
        'incidentDateInput.required': 'You must enter the Date of Collison being investigated.',
        'incidentDateInput.minDate': 'Date of Collision being investigated must be on or after Jan 1st, 2010.',
        'incidentDateInput.maxDate': 'Date of Collision being investigated cannot be a future date.',
        'vehicleStartYearInput.max': "Vehicle Model Start Year (YYYY) must be 4 digit numeric and cannot be later than the Vehicle Model End Year (YYYY).",
        'vehicleStartYearInput.min': "Vehicle Model Start Year (YYYY) must be 4 digit numeric and be on or after the year 1800.",
        'vehicleEndYearInput.min': "Vehicle Model End Year (YYYY) must be 4 digit numeric and be on or after the year 1800, or it must be the same as or later than the Vehicle Model Start Year (YYYY).",
        'collisionStartDateInput.required': 'You must enter the MTO Search Date Range (Start Date).',
        'collisionEndDateInput.required': 'You must enter the MTO Search Date Range (End Date).',
        'collisionStartDateInput.minDate': 'MTO Search Date Range (Start Date) must be on or after Jan 1st, 2010.',
        'collisionStartDateInput.maxDate': 'MTO Search Date Range (Start Date) cannot be a future date, or a date later than the MTO Search Date Range (End Date).',
        'collisionEndDateInput.minDate': 'MTO Search Date Range (End Date) must be on or after Jan 1, 2010, or it must be the same as or later than MTO Search Date Range (Start Date).',
        'collisionEndDateInput.maxDate': 'MTO Search Date Range (End Date) cannot be a future date.',
        'vehiclePlateInput.pattern': 'Plate Number cannot contain special characters.'
    };

    transform(inc: string) {

        let response = "Unmapped Error";
        for (const [key, value] of Object.entries(this.errorMap)) {
            if (key === inc) { response = value; }
        }
        return response;
    }

}