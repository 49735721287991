import { Component, OnDestroy, OnInit } from '@angular/core';
import { DownloadService } from '../services/download.service';
import { ProfileType } from '../models/Profile';
import { UserService } from '../services/user.service';
import { filter, Subject, Subscription, take } from 'rxjs';
import { AlertPanelComponent } from '../validation/alert-panel/alert-panel.component';
import { scrollToAlertPanel } from '../utils';
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-download-page',
  standalone: true,
  imports: [AlertPanelComponent, LoadingSpinnerComponent],
  templateUrl: './download-page.component.html',
  styleUrl: './download-page.component.css'
})
export class DownloadPageComponent implements OnInit, OnDestroy {
  constructor(private downloadService: DownloadService, private userService: UserService) { }

  showSuccess = false;
  showError = false;
  showWarning = false;
  loading = true;
  profile!: ProfileType;

  ngOnDestroy(): void {
    this.downloadSub?.unsubscribe();
  }

  profile$!: Subject<ProfileType>;

  downloadSub!: Subscription;

  ngOnInit(): void {
    this.userService.profile$.pipe(
      filter((profile) => profile.userPrincipalName != ""),
      take(1))
      .subscribe(
        {
          next: (profile) => {
            this.profile = profile;
          },
          error: (err) => {
            console.error(err);
          },
          complete: () => {
            console.log("Complete Init");
          }
        });

    this.downloadService.downloadCheck(this.profile.userPrincipalName).pipe(
      take(1)).subscribe({
        next: () => {
          this.loading = false;
          this.showWarning = false;
        },
        error: (err: HttpErrorResponse) => {
          this.loading = false;
          this.showWarning = true;
        },
        complete: () => {
          console.log("Checked current file status");
        }
      });
  }

  downloadTheThing() {
    this.loading = true;
    this.downloadSub = this.downloadService.download(this.profile.userPrincipalName)
      .subscribe({
        next: (result) => {
          const url = window.URL.createObjectURL(result);

          const a = document.createElement('a');
          a.href = url;
          a.download = 'OPP Collision Data Output File.csv';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          this.showError = false;
          this.showWarning = false;
          this.showSuccess = true;
          this.loading = false;
          scrollToAlertPanel();
        },
        error: (err) => {
          this.showSuccess = false;
          this.showWarning = false;
          this.showError = true;
          this.loading = false;
          scrollToAlertPanel();
          console.error(err);
        },
        complete: () => {
          console.log("Completed Download");
        }
      });
  }
}
