import { Routes } from '@angular/router';
import { RequestFormsComponent } from './request-forms/request-forms.component';
import { DownloadPageComponent } from './download-page/download-page.component';
import { MsalGuard } from '@azure/msal-angular';

export const routes: Routes = [
  {
    path: '',
    redirectTo: `request`,
    pathMatch: 'full',
  },
  {
    path: `request`,
    component: RequestFormsComponent,
    data: { subTitle: "OPP Collision Data Search Form" },
    canActivate: [MsalGuard]
  },
  {
    path: `download`,
    component: DownloadPageComponent,
    data: { subTitle: "Download Page" },
    canActivate: [MsalGuard]
  }
];
