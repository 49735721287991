import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

/** 
 * Form utilities to add errors to an array and then
 * Errors names are made with the format {control}.{error}
 * You can also optionally check the externalControl for its own validations which will just return the name of the error.
 * */

export const getFormValidationErrors = (inputForm: FormGroup, errorArray: string[], checkParent: boolean = false): void => {

    // Optional top level check
    if (checkParent) {
        const topControlError: ValidationErrors | null | undefined = inputForm.errors;
        if (topControlError != null) {
            Object.keys(topControlError).forEach((keyError) => {
                const err = keyError;
                if (!errorArray.includes(err)) {
                    errorArray.push(err);
                }
            });
        }
    }

    // Checking all nested Form Controls
    Object.keys(inputForm.controls).forEach((item) => {
        if (inputForm.get(item) instanceof FormGroup || inputForm.get(item) instanceof FormArray) {
            getFormValidationErrors(inputForm.get(item) as FormGroup, errorArray);
        }
        const controlErrors: ValidationErrors | null | undefined = inputForm.get(item)?.errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach((keyError) => {
                const err = item + '.' + keyError;
                if (!errorArray.includes(err)) {
                    errorArray.push(err);
                }
            });
        }
    });
};

export const scrollToValidation = (error: string): void => {
    var id = error.split('.')[0];
    let elementToScrollTo;
    if (id == "radioButtons") {
        elementToScrollTo = document.getElementById('radioButtonsYes');
    } else {
        elementToScrollTo = document.getElementById(id);
    }
    if (elementToScrollTo) {
        elementToScrollTo.focus();
    }
};

export const scrollToAlertPanel = (): void => {
    setTimeout(() => {
        let alertPanel = document.querySelector('#alertPanel');

        if (alertPanel) {
            (alertPanel.firstChild as HTMLElement).focus();
        }
    }, 100);
};

export const checkInvalidAndRemoveFromErrors = (
    inputForm: FormGroup,
    control: string,
    listOfErrors: string[],
): void => {
    const targetControl = inputForm.get(control);

    const err = listOfErrors.find((x) => {
        return x.includes(control);
    });

    if (err !== undefined) {
        listOfErrors.splice(listOfErrors.indexOf(err), 1);
    }

    // check if the form control is valid
    if (!targetControl?.valid) {
        getFormValidationErrors(inputForm, listOfErrors);
    } else {
        // if it's valid then remove it form the alert
        const err = listOfErrors.find((x) => {
            return x.includes(control);
        });

        if (err !== undefined) {
            listOfErrors.splice(listOfErrors.indexOf(err), 1);
        }
    }
};

export function maxDateValidator(maxDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const selectedDate = new Date(control.value + "T00:00:00");
        const currentDate = maxDate;

        if (selectedDate > currentDate) {
            return { maxDate: true };
        }

        return null;
    };
}

export function minDateValidator(minDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const selectedDate = new Date(control.value + "T00:00:00");

        if (selectedDate < minDate) {
            return { minDate: true };
        }

        return null;
    };
}