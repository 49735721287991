export enum DamageAreaEnum {
    IMPACT00 = '00-Unknown',
    IMPACT01 = '01-Right front corner',
    IMPACT02 = '02-Right front',
    IMPACT03 = '03-Right centre',
    IMPACT04 = '04-Right rear',
    IMPACT05 = '05-Right rear corner',
    IMPACT06 = '06-Back centre',
    IMPACT07 = '07-Left rear corner',
    IMPACT08 = '08-Left rear',
    IMPACT09 = '09-Left centre',
    IMPACT10 = '10-Left front',
    IMPACT11 = '11-Left front corner',
    IMPACT12 = '12-Front centre',
    IMPACT13 = '13-Front complete',
    IMPACT14 = '14-Right side complete',
    IMPACT15 = '15-Back complete',
    IMPACT16 = '16-Left side complete',
    IMPACT17 = '17-Top',
    IMPACT18 = '18-Undercarriage',
    IMPACT19 = '19-No contact'
}