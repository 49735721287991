import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Item } from '../models/item';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubmitFormService {

  constructor(private httpClient: HttpClient) { }

  private headers = new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "application/json"
  });

  submitForm(item: Item): Observable<any> {
    const url = `${environment.apiConfig.server.APIEndpoint}/rpd/submit`;

    return this.httpClient.post<any>(url, item, { headers: this.headers });
  }
}
