import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterOutlet, RoutesRecognized } from '@angular/router';
import { UserService } from './services/user.service';
import { Subscription, take } from 'rxjs';
import { AlertPanelComponent } from "./validation/alert-panel/alert-panel.component";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { ProfileType } from './models/Profile';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AlertPanelComponent, LoadingSpinnerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'DIMA_RPD_SourceCode_UI';

  subTitle: string | undefined;
  titleSub!: Subscription;

  brickTheApp = false;
  brickWithWarning = false;
  loading = true;
  errorMessage!: string;

  constructor(private userService: UserService, private router: Router) {

    this.userService.availability().subscribe({
      next: () => {
        this.userService.getProfile()
          .pipe(take(1))
          .subscribe(
            (profile: ProfileType) => {
              this.userService.profile$.next(profile);
              this.userService.checkEmailExists(profile.userPrincipalName)
                .pipe(take(1))
                .subscribe({
                  next: () => {
                    this.brickTheApp = false;
                    this.loading = false;
                  },
                  error: (err: HttpErrorResponse) => {
                    this.brickTheApp = true;
                    console.error(err);
                    if (err.status === 500) {
                      this.errorMessage = "Server is down.";
                    }
                    else {
                      this.errorMessage = "You do not have the authority required to access this application. Contact Collision.Reporting.Support@ontario.ca for further assistance.";
                    }
                    this.loading = false;
                  },
                  complete: () => {
                    console.log("Email check complete");
                  }
                });
            });
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 418) {
          this.brickWithWarning = true;
          this.errorMessage = "Our business hours for submitting queries are Monday at 8:00 AM to Friday at 6:00 PM, continuously. Unfortunately, any submissions made outside of this timeframe cannot be processed.";
        }
        else {
          this.brickTheApp = true;
          this.errorMessage = "Server is down.";
        }
        this.loading = false;
      },
      complete: () => {
        console.log("Availibility check complete");
      }
    });
  }

  ngOnDestroy(): void {
    this.titleSub.unsubscribe();
  }

  ngOnInit(): void {
    this.titleSub = this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) { this.subTitle = data.state.root.firstChild?.data["subTitle"]; }
    });
  }



}
