<div class='ontario-row'>

    @if(loading){
    <app-loading-spinner [fullScreen]="true"></app-loading-spinner>
    }
    @if (showError) {
    <app-alert-panel id="alertPanel" [alertTitle]="'There is a problem'"
        [alertMessage]="'There was a server error, try again later!'" [alertType]="'error'"></app-alert-panel>
    }
    @if (showWarning) {
    <app-alert-panel id="alertPanel" [alertTitle]="'There is a problem'"
        [alertMessage]="'As of yet, there is no file to download associated with this account.'"
        [alertType]="'warning'"></app-alert-panel>
    }
    @if (showSuccess) { <app-alert-panel id="successPanel" [alertTitle]="'Success'"
        [alertMessage]="'Download should begin shortly!'" [alertType]="'success'">
    </app-alert-panel>
    }
    @else {
    <h2 style="margin-top: 1.5rem;" class="ontario-columns ontario-small-12">Download Page</h2>
    <div class="ontario-columns ontario-small-12">
        <p style="margin: 2rem 0 2.5rem 0; width: fit-content;">
            This is the OPP Failure to Remain download page.
        </p>
    </div>
    <div class="ontario-columns ontario-small-12">
        <button (click)="downloadTheThing()" class="ontario-button ontario-button--primary"
            style="margin-bottom: 5rem;">
            Download File
        </button>
    </div>
    }
</div>