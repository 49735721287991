<div class='ontario-row'>
    <div class='ontario-columns ontario-small-12'>

        @if(loading){
        <app-loading-spinner [fullScreen]="true"></app-loading-spinner>
        }

        @if (showAlert && listOfErrors.length > 0) {
        <app-alert-panel id="alertPanel" [alertTitle]="'There is a problem'"
            [alertMessage]="'Errors were found on this page:'" [alertType]="'error'" [alertErrorList]="listOfErrors"
            (scrollToValidation)="scrollToValidation($event)"></app-alert-panel>
        }
        @else if (showAlert) {
        <app-alert-panel id="alertPanel" [alertTitle]="'There is a problem'"
            [alertMessage]="'There was a problem in the request submission. Please try again later.'"
            [alertType]="'error'"></app-alert-panel>
        }

        @if (showSuccess && listOfErrors.length <= 0) { <app-alert-panel id="successPanel" [alertTitle]="'Success'"
            [alertMessage]="'Your request has been successfully submitted. You will receive an email notification once your output file is ready.'"
            [alertType]="'success'">
            </app-alert-panel>
            }

            @if (!showSuccess){
            <h2 style="margin-top: 1.5rem;">OPP Collision Data Search Form</h2>
            <p class="ontario-lead-statement">
                This form is used to search MTO collision data to aid in 'failure to remain' investigations. Please
                input as much known selection criteria relating to the suspect vehicle as possible to ensure the search
                is specific.
            </p>
            <p class="ontario-hint" style="max-width: none;">
                <b>Please Note: This form is available for submissions from Monday at 8:00 AM to Friday at 6:00 PM,
                    continuously.
                    Unfortunately, any submissions made outside of this timeframe cannot be processed.​</b>
            </p>

            <hr>
            <h3>Informational Fields</h3>
            <p>
                This section comprises of the first 3 questions that are required information from OPP in order to track
                the
                request.
            </p>

            <form [formGroup]="inputForm">


                <fieldset style="margin-bottom: 1.5rem;">
                    <legend class="ontario-fieldset__legend">
                        1. Please confirm that this request is made under s. 42(1)(g) of the Freedom of Information
                        and
                        Protection of Privacy Act. <span class="ontario-label__flag">(required)</span>
                    </legend>
                    <p class="ontario-hint">NOTE: Please choose one of the two options given below:</p>
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                        viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                    @if(radioButtons.invalid && radioButtons.touched){
                    <app-inline-validation [fieldName]="'radioButtons'"
                        [message]="'Request must be made under s. 42(1)(g) of the Freedom of Information and Protection of Privacy Act.'"></app-inline-validation>
                    }
                    <div class="ontario-radios__item">

                        <input id="radioButtonsYes" (change)="checkInvalid('radioButtons')" type="radio"
                            class="ontario-radios__input" formControlName="radioButtons" name="radioButtons"
                            [value]='true' />
                        <label [ngClass]="{'ontario-input__error': radioButtons.touched && radioButtons.invalid }"
                            class="ontario-label ontario-radios__label" for="radioButtonsYes">Yes</label>
                    </div>

                    <div class="ontario-radios__item">
                        <input id="radioButtonsNo" (change)="checkInvalid('radioButtons')" type="radio"
                            class="ontario-radios__input" formControlName="radioButtons" name="radioButtons"
                            [value]='false' />
                        <label [ngClass]="{'ontario-input__error': radioButtons.touched && radioButtons.invalid }"
                            class="ontario-label ontario-radios__label" for="radioButtonsNo">No</label>
                    </div>

                </fieldset>

                <label class="ontario-label" for="fileNumberInput">
                    2. File Number or Occurrence Number<span class="ontario-label__flag">(required)</span>
                </label>
                @if (fileNumberInput.invalid && fileNumberInput.touched) {
                <app-inline-validation
                    [message]="'You must enter the File Number or Occurrence Number.'"></app-inline-validation>
                }
                <input [ngClass]="{'ontario-input__error': fileNumberInput.touched && fileNumberInput.invalid }"
                    (change)="checkInvalid('fileNumberInput')" class="ontario-input" type="text"
                    formControlName="fileNumberInput" id="fileNumberInput" placeholder="Enter your answer">

                <label class="ontario-label" for="incidentDateInput">
                    3. Date of Collision being investigated.<span class="ontario-label__flag">(required)</span>
                </label>
                <p class="ontario-hint"> NOTE: MTO collision records can only be queried back to 2010.</p>
                @if (incidentDateInput.hasError('required') && incidentDateInput.touched) {
                <app-inline-validation
                    [message]="'You must enter the Date of Collison being investigated.'"></app-inline-validation>
                }
                @if (incidentDateInput.hasError('maxDate') && incidentDateInput.touched) {
                <app-inline-validation
                    [message]="'Date of Collision being investigated cannot be a future date.'"></app-inline-validation>
                }
                @if (incidentDateInput.hasError('minDate') && incidentDateInput.touched) {
                <app-inline-validation
                    [message]="'Date of Collision being investigated must be on or after Jan 1st, 2010.'"></app-inline-validation>
                }
                <input [ngClass]="{'ontario-input__error': incidentDateInput.touched && incidentDateInput.invalid }"
                    (change)="checkInvalid('incidentDateInput')" class="ontario-input" type="date"
                    [max]="maxDate | date: 'YYYY-MM-dd'" formControlName="incidentDateInput" id="incidentDateInput">
                <hr>
                <h3>Search Criteria</h3>
                <p>
                    This section comprises of the remaining 11 questions that will be used by MTO as search criteria for
                    their collision records. </p>

                <h3 class="ontario-label" for="vehicleMakeInput">
                    1. Vehicle Make Code <span class="ontario-label__flag">(optional)</span>
                </h3>
                <p class="ontario-hint">NOTE: To provide the correct value for Vehicle Make Code, please refer to the
                    'NCI_MAKE_ABBR_CODE' column in the document titled <i>Vehicle Make Model Mapping.xlsx</i>, which is
                    available on SharePoint.</p>
                <div id="vehicleMakeInput" formArrayName="vehicleMakeInput">
                    @for (make of vehicleMakeInput.controls; track make) {
                    <label style="max-width: 48rem; padding: 0 2rem 0.5rem 0.5rem;" for="makeInput{{$index}}">
                        <span>Make {{$index + 1}}:</span>
                        <button style="float: right;" class="delete-button"
                            (click)="removeFromFormArray(vehicleMakeInput,$index)"
                            attr.aria-label="delete make {{$index + 1}}">
                        </button>
                    </label>
                    <input appInputFocus [afterParentViewInit]="initialPaintDone" (change)="checkInvalid('{{$index}}')"
                        class="ontario-input" type="text" formControlName="{{$index}}" id="makeInput{{$index}}"
                        placeholder="e.g. DODG" maxlength="4">
                    }
                </div>
                <button class="ontario-button ontario-button--primary" (click)="addToFormArray(vehicleMakeInput)">Add
                    Make</button>

                <h3 class="ontario-label" for="vehicleModelInput">
                    2. Vehicle Model Code<span class="ontario-label__flag">(optional)</span>
                </h3>
                <p class="ontario-hint">NOTE: To provide the correct value for Vehicle Model Code, please refer to the
                    'MTO_MODEL_CODE' column in the document titled <i>Vehicle Make Model Mapping.xlsx</i>, which is
                    available
                    on SharePoint.</p>
                <div id="vehicleModelInput" formArrayName="vehicleModelInput">
                    @for (model of vehicleModelInput.controls; track model) {
                    <label style="max-width: 48rem; padding: 0 2rem 0.5rem 0.5rem;" for="modelInput{{$index}}">
                        <span>Model {{$index + 1}}:</span>
                        <button style="float: right;" class="delete-button"
                            (click)="removeFromFormArray(vehicleModelInput,$index)"
                            attr.aria-label="delete model {{$index + 1}}">
                        </button>
                    </label>
                    <input appInputFocus [afterParentViewInit]="initialPaintDone" (change)="checkInvalid('{{$index}}')"
                        class="ontario-input" type="text" formControlName="{{$index}}" id="modelInput{{$index}}"
                        placeholder="e.g. CHG" maxlength="4">
                    }
                </div>
                <button class="ontario-button ontario-button--primary" (click)="addToFormArray(vehicleModelInput)">Add
                    Model</button>

                <label class="ontario-label" for="vehicleStartYearInput">
                    3. Vehicle Model Start Year (YYYY)<span class="ontario-label__flag">(optional)</span>
                </label>
                @if (vehicleStartYearInput.hasError('max') && vehicleStartYearInput.touched) {
                <app-inline-validation
                    [message]="'Vehicle Model Start Year (YYYY) must be 4 digit numeric and cannot be later than the Vehicle Model End Year (YYYY).'"></app-inline-validation>
                }
                @if (vehicleStartYearInput.hasError('min') && vehicleStartYearInput.touched) {
                <app-inline-validation
                    [message]="'Vehicle Model Start Year (YYYY) must be 4 digit numeric and be on or after the year 1800.'"></app-inline-validation>
                }
                <input (change)="updateMinModelDate()" class="ontario-input"
                    [ngClass]="{'ontario-input__error': vehicleStartYearInput.touched && vehicleStartYearInput.invalid }"
                    type="text" inputmode="numeric" mask="0000" formControlName="vehicleStartYearInput"
                    id="vehicleStartYearInput" placeholder="The value must be a number" min="1800">

                <label class="ontario-label" for="vehicleEndYearInput">
                    4. Vehicle Model End Year (YYYY)<span class="ontario-label__flag">(optional)</span>
                </label>
                <p class="ontario-hint">NOTE: Vehicle Model End Year (YYYY) must be greater than or equal to the Vehicle
                    Model Start Year (YYYY).</p>
                @if (vehicleEndYearInput.hasError('min') && vehicleEndYearInput.touched) {
                <app-inline-validation
                    [message]="'Vehicle Model End Year (YYYY) must be 4 digit numeric and be on or after the year 1800, or it must be the same as or later than the Vehicle Model Start Year (YYYY).'"></app-inline-validation>
                }
                <input (change)="updateMaxModelDate()" class="ontario-input"
                    [ngClass]="{'ontario-input__error': vehicleEndYearInput.touched && vehicleEndYearInput.invalid }"
                    type="text" inputmode="numeric" mask="0000" formControlName="vehicleEndYearInput"
                    id="vehicleEndYearInput" placeholder="The value must be a number" min="1800">

                <fieldset>
                    <legend class="ontario-fieldset__legend">
                        5. Vehicle Colour <span class="ontario-label__flag">(optional)</span>
                    </legend>
                    <p class="ontario-hint">NOTE: Select all that apply.</p>
                    <form formArrayName="colorFormArray">
                        <div class="ontario-checkboxes grid-container">
                            @for (color of colorFormArray.controls; track $index) {
                            <div class="ontario-checkboxes__item grid-item">
                                <input [id]="getColor($index)" (change)="checkInvalid('colorFormArray')" type="checkbox"
                                    class="ontario-checkboxes__input" [formControlName]="$index">
                                <label class="ontario-checkboxes__label"
                                    [for]="getColor($index)">{{getColor($index)}}</label>
                            </div>
                            }
                        </div>
                    </form>
                </fieldset>

                <h3 class="ontario-label" for="vehicleBodyTypeSection">
                    6. Vehicle Body Style Code <span class="ontario-label__flag">(optional)</span>
                </h3>
                <p class="ontario-hint">NOTE: To provide the correct value for Vehicle Body Style Code, please refer to
                    the 'VehicleBodyTypeLCSCode' column in the document titled <i>Vehicle Body Style Mapping.xlsx</i>,
                    which is available on SharePoint.</p>
                <div id="vehicleBodyTypeInput" formArrayName="vehicleBodyTypeInput">
                    @for (body of vehicleBodyTypeInput.controls; track body) {
                    <label style="max-width: 48rem; padding: 0 2rem 0.5rem 0.5rem;" for="bodyTypeInput{{$index}}">
                        <span>Body Style {{$index + 1}}:</span>
                        <button style="float: right;" class="delete-button"
                            (click)="removeFromFormArray(vehicleBodyTypeInput,$index)"
                            attr.aria-label="delete body style {{$index + 1}}">
                        </button>
                    </label>
                    <input appInputFocus [afterParentViewInit]="initialPaintDone" (change)="checkInvalid('{{$index}}')"
                        class="ontario-input" type="text" formControlName="{{$index}}" id="bodyTypeInput{{$index}}"
                        placeholder="e.g. 2D" maxlength="3">
                    }
                </div>
                <button class="ontario-button ontario-button--primary"
                    (click)="addToFormArray(vehicleBodyTypeInput)">Add
                    Body Style</button>

                <label class="ontario-label" for="vehiclePlateInput">
                    7. Plate Number<span class="ontario-label__flag">(optional)</span>
                </label>
                <div class="ontario-callout">
                    <h3 class="ontario-callout__title ontario-h5"><u>NOTE: Partial Plate Number Search Guide</u></h3>
                    <p class="ontario-hint">Example Plate Number: XSWC620<br><br>
                        If the end of the plate is unknown: XSW%<br>
                        If the beginning of the plate is unknown: %620<br>
                        If the beginning and ending of the plate is unknown: %W%<br>
                        If a single value in the middle of the plate is unknown: XSW_620 <br>
                        If multiple values in the middle of the plate are unknown: XS%620 <br>
                        If searching for multiple characters: XSWC62[0o]<br>
                        If searching for multiple characters and the beginning of the plate is unknown: %C62[0o]</p>
                </div>
                @if (vehiclePlateInput.hasError('pattern') && vehiclePlateInput.touched) {
                <app-inline-validation
                    [message]="'Plate Number cannot contain special characters.'"></app-inline-validation>
                }
                <input (change)="checkInvalid('vehiclePlateInput')" class="ontario-input"
                    [ngClass]="{'ontario-input__error': vehiclePlateInput.touched && vehiclePlateInput.invalid }"
                    type="text" formControlName="vehiclePlateInput" id="vehiclePlateInput"
                    placeholder="Enter your answer">

                <label class="ontario-label" for="municipalityNumberInput">
                    8. Municipality Number<span class="ontario-label__flag">(optional)</span>
                </label>
                <p class="ontario-hint">NOTE: To provide the correct value for Municipality Number, please refer to the
                    'Municipality' column in the document titled <i>Municipality Mapping.xlsx</i>,
                    which is available on SharePoint.</p>
                <input (change)="checkInvalid('municipalityNumberInput')" class="ontario-input" type="text"
                    inputmode="numeric" mask="0*" formControlName="municipalityNumberInput" id="municipalityNumberInput"
                    placeholder="The value must be a number">

                <fieldset>
                    <legend class="ontario-fieldset__legend">
                        9. Location of Vehicle Damage or Area of Impact <span
                            class="ontario-label__flag">(optional)</span>
                    </legend>
                    <p class="ontario-hint">NOTE: Select all that apply.</p>
                    <form formArrayName="damageAreaFormArray">
                        <div class="ontario-checkboxes grid-container">
                            @for (damageArea of damageAreaFormArray.controls; track $index) {
                            <div class="ontario-checkboxes__item grid-item">
                                <input [id]="getDamageArea($index)" (change)="checkInvalid('damageAreaFormArray')"
                                    type="checkbox" class="ontario-checkboxes__input" [formControlName]="$index">
                                <label class="ontario-checkboxes__label"
                                    [for]="getDamageArea($index)">{{getDamageArea($index)}}</label>
                            </div>
                            }
                        </div>
                    </form>
                </fieldset>

                <label class="ontario-label" for="collisionStartDateInput">
                    10. MTO Search Date Range (Start Date)<span class="ontario-label__flag">(required)</span>
                </label>
                <p class="ontario-hint">NOTE: MTO collision records can only be queried back to 2010.</p>
                @if (collisionStartDateInput.hasError('required') && collisionStartDateInput.touched) {
                <app-inline-validation
                    [message]="'You must enter the MTO Search Date Range (Start Date).'"></app-inline-validation>
                }
                @if (collisionStartDateInput.hasError('maxDate') && collisionStartDateInput.touched) {
                <app-inline-validation
                    [message]="'MTO Search Date Range (Start Date) cannot be a future date or a date later than the MTO Search Date Range (End Date).'"></app-inline-validation>
                }
                @if (collisionStartDateInput.hasError('minDate') && collisionStartDateInput.touched) {
                <app-inline-validation
                    [message]="'MTO Search Date Range (Start Date) must be on or after Jan 1st, 2010.'"></app-inline-validation>
                }
                <input
                    [ngClass]="{'ontario-input__error': collisionStartDateInput.touched && collisionStartDateInput.invalid }"
                    (change)="updateMinDate()" class="ontario-input" type="date"
                    formControlName="collisionStartDateInput" id="collisionStartDateInput"
                    [max]="maxStartDate ?? maxDate | date: 'yyyy-MM-dd'" [min]="minDate | date: 'yyyy-MM-dd'">

                <label class="ontario-label" for="collisionEndDateInput">
                    11. MTO Search Date Range (End Date) <span class="ontario-label__flag">(required)</span>
                </label>
                <p class="ontario-hint">NOTE: MTO collision records can only be queried back to 2010.</p>
                @if (collisionEndDateInput.hasError('required') && collisionEndDateInput.touched) {
                <app-inline-validation
                    [message]="'You must enter the MTO Search Date Range (End Date).'"></app-inline-validation>
                }
                @if (collisionEndDateInput.hasError('maxDate') && collisionEndDateInput.touched) {
                <app-inline-validation
                    [message]="'MTO Search Date Range (End Date) cannot be a future date.'"></app-inline-validation>
                }
                @if (collisionEndDateInput.hasError('minDate') && collisionEndDateInput.touched) {
                <app-inline-validation
                    [message]="'MTO Search Date Range (End Date) must be on or after Jan 1, 2010, or it must be the same as or later than the MTO Search Date Range (Start Date).'"></app-inline-validation>
                }
                <input
                    [ngClass]="{'ontario-input__error': collisionEndDateInput.touched && collisionEndDateInput.invalid }"
                    class="ontario-input" type="date" (change)="updateMaxDate()" formControlName="collisionEndDateInput"
                    id="collisionEndDateInput" [max]="maxDate | date: 'yyyy-MM-dd'"
                    [min]="minEndDate ?? minDate | date: 'yyyy-MM-dd'">
            </form>
            <button class="ontario-button ontario-button--primary" type="submit" (click)="submit()"> Submit </button>
            }
    </div>
</div>