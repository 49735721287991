export enum ColorEnum {
    WHITE = 'White',
    BLACK = 'Black',
    BLUE = 'Blue',
    UK = 'Unknown',
    GREEN = 'Green',
    GREY = 'Grey',
    RED = 'Red',
    BEIGE = 'Beige',
    ORANGE = 'Orange',
    SILVER = 'Silver, Aluminium, Stainless Steel',
    BROWN = 'Brown',
    YELLOW = 'Yellow',
    GOLD = 'Gold',
    BRONZE = 'Bronze',
    MAROON = 'Maroon',
    PURPLE = 'Purple, Lavender, Mauve',
    COPPER = 'Copper',
    PINK = 'Pink',
    TAN = 'Tan',
    TURQUOISE = 'Turquoise',
    MULTICOLOUR = 'Multicolour',
    CREAM = 'Cream, Ivory'
}
