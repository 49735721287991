import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { Item } from '../models/item';
import { SubmitFormService } from '../services/submit-form.service';
import { DamageAreaEnum } from '../models/DamageAreaEnum';
import { ColorEnum } from '../models/ColorEnum';
import { AlertPanelComponent } from "../validation/alert-panel/alert-panel.component";
import { checkInvalidAndRemoveFromErrors, getFormValidationErrors, maxDateValidator, minDateValidator, scrollToAlertPanel, scrollToValidation } from '../utils';
import { DatePipe, NgClass } from '@angular/common';
import { InlineValidationComponent } from '../validation/inline-validation/inline-validation.component';
import { ProfileType } from '../models/Profile';
import { UserService } from '../services/user.service';
import { filter, Subject, Subscription, take } from 'rxjs';
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";
import { NgxMaskDirective } from 'ngx-mask';
import { InputFocusDirective } from '../input-focus.directive';


@Component({
  selector: 'app-request-forms',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AlertPanelComponent,
    InlineValidationComponent,
    NgClass,
    DatePipe,
    LoadingSpinnerComponent,
    NgxMaskDirective,
    InputFocusDirective
  ],
  templateUrl: './request-forms.component.html',
  styleUrl: './request-forms.component.css'
})
export class RequestFormsComponent implements OnInit, OnDestroy {

  constructor(private submitService: SubmitFormService, private userService: UserService) { }


  ngOnDestroy(): void {
    this.requestSub?.unsubscribe();
  }

  requestSub!: Subscription;


  scrollToValidation = scrollToValidation;

  initialPaintDone = false;
  profile$!: Subject<ProfileType>;
  inputForm!: FormGroup;
  listOfErrors = [];
  showAlert = false;
  showSuccess = false;
  loading = false;

  itemToSubmit!: Partial<Item>;

  maxDate = new Date();
  minDate = new Date('2010-01-01T00:00:00');
  maxStartDate: Date | undefined;
  minEndDate: Date | undefined;

  maxStartDateValidator = maxDateValidator(this.maxDate);
  minEndDateValidator = minDateValidator(this.minDate);

  max!: number;
  min = 1800;

  maxModelDateValidator!: ValidatorFn;
  minModelDateValidator = Validators.min(this.min);

  ngOnInit(): void {
    this.userService.profile$.pipe(
      filter((profile) => profile.userPrincipalName != "undefined"),
      take(1))
      .subscribe(
        {
          next: (profile) => {
            this.itemToSubmit = {
              productId: 'RPD',
              email: profile.userPrincipalName,
              userName: profile.givenName + " " + profile.surname,
              expectedFileFormat: 'JSON'
            };

          },
          error: (err) => {
            console.error(err);
          },
          complete: () => {
            console.log("Complete Init");
          }
        });
    this.initEmptyForm();
  }

  initEmptyForm(): void {
    this.inputForm = new FormGroup({
      radioButtons: new FormControl(false, Validators.requiredTrue),
      fileNumberInput: new FormControl("", Validators.required),
      incidentDateInput: new FormControl("",
        [
          Validators.required,
          minDateValidator(this.minDate),
          maxDateValidator(this.maxDate)
        ]
      ),
      vehicleMakeInput: new FormArray([new FormControl("")]),
      vehicleStartYearInput: new FormControl(null,
        [
          Validators.min(1800),
        ]
      ),
      vehicleEndYearInput: new FormControl(null,
        [
          this.minModelDateValidator
        ]
      ),
      vehicleModelInput: new FormArray([new FormControl("")]),
      colorFormArray: new FormArray([]),
      vehicleBodyTypeInput: new FormArray([new FormControl("")]),
      vehiclePlateInput: new FormControl("", [Validators.pattern('^(?!.*%%)[%a-zA-Z0-9_]*(%|_|[[a-zA-Z0-9]+])*[%a-zA-Z0-9_]*$')]),
      municipalityNumberInput: new FormControl(),
      damageAreaFormArray: new FormArray([]),
      collisionStartDateInput: new FormControl("",
        [
          Validators.required,
          minDateValidator(new Date('2010-01-01T00:00:00')),
          maxDateValidator(this.maxDate),
          this.maxStartDateValidator
        ]
      ),
      collisionEndDateInput: new FormControl("",
        [
          Validators.required,
          minDateValidator(new Date('2010-01-01T00:00:00')),
          this.minEndDateValidator,
          maxDateValidator(this.maxDate),
        ]
      )
    });

    Object.values(ColorEnum).forEach(() =>
      this.colorFormArray.controls.push(
        new FormControl(false),
      ),
    );

    Object.values(DamageAreaEnum).forEach(() =>
      this.damageAreaFormArray.controls.push(
        new FormControl(false),
      ),
    );
  }

  addToFormArray(controls: FormArray) {
    this.initialPaintDone = true;
    controls.push(new FormControl(""));
  }

  removeFromFormArray(controls: FormArray, index: number) {
    controls.removeAt(index);
  }

  updateMaxDate() {
    if (!this.collisionEndDateInput.value || this.maxDate < new Date(this.collisionEndDateInput.value + "T00:00:00")) {
      this.maxStartDate = this.maxDate;
      return;
    }

    this.maxStartDate = this.collisionEndDateInput.value;

    if (this.maxStartDate) {
      this.collisionStartDateInput.removeValidators(this.maxStartDateValidator);
      this.maxStartDateValidator = maxDateValidator(new Date(this.collisionEndDateInput.value + "T00:00:00"));
      this.collisionStartDateInput.addValidators(this.maxStartDateValidator);
    }

    this.collisionStartDateInput.updateValueAndValidity();
    this.checkInvalid('collisionStartDateInput');
    this.checkInvalid('collisionEndDateInput');

  }

  updateMinDate() {
    if (this.minDate > new Date(this.collisionStartDateInput.value + "T00:00:00")) {
      this.minEndDate = this.minDate;
      return;
    }

    this.minEndDate = this.collisionStartDateInput.value;

    if (this.minEndDate) {
      this.collisionEndDateInput.removeValidators(this.minEndDateValidator);
      this.minEndDateValidator = minDateValidator(new Date(this.collisionStartDateInput.value + "T00:00:00"));
      this.collisionEndDateInput.addValidators(this.minEndDateValidator);
    }
    this.collisionEndDateInput.updateValueAndValidity();
    this.checkInvalid('collisionEndDateInput');
    this.checkInvalid('collisionStartDateInput');

  }

  updateMaxModelDate() {
    if (!this.vehicleEndYearInput.value) {
      // Remove the max validator if end year is cleared
      this.vehicleStartYearInput.removeValidators(this.maxModelDateValidator);
    } else {
      this.max = this.vehicleEndYearInput.value;
      this.vehicleStartYearInput.removeValidators(this.maxModelDateValidator);
      this.maxModelDateValidator = Validators.max(this.max);
      this.vehicleStartYearInput.addValidators(this.maxModelDateValidator);
    }

    this.vehicleStartYearInput.updateValueAndValidity();
    this.checkInvalid('vehicleStartYearInput');
    this.checkInvalid('vehicleEndYearInput');

  }

  updateMinModelDate() {
    if (!this.vehicleStartYearInput.value || this.vehicleStartYearInput.value < 1800) {
      this.min = 1800;
    } else {
      this.min = this.vehicleStartYearInput.value;
    }

    this.vehicleEndYearInput.removeValidators(this.minModelDateValidator);
    this.minModelDateValidator = Validators.min(this.min);
    this.vehicleEndYearInput.addValidators(this.minModelDateValidator);

    this.vehicleEndYearInput.updateValueAndValidity();
    this.checkInvalid('vehicleStartYearInput');
    this.checkInvalid('vehicleEndYearInput');

  }

  getColor(idx: number): string {
    return Object.values(ColorEnum)[idx];
  }

  getDamageArea(idx: number): string {
    return Object.values(DamageAreaEnum)[idx];
  }

  get radioButtons(): FormGroup {
    return this.inputForm.get('radioButtons') as FormGroup;
  }

  get fileNumberInput(): FormControl {
    return this.inputForm.get('fileNumberInput') as FormControl;
  }

  get incidentDateInput(): FormControl {
    return this.inputForm.get('incidentDateInput') as FormControl;
  }

  get vehicleMakeInput(): FormArray {
    return this.inputForm.get('vehicleMakeInput') as FormArray;
  }

  get vehicleStartYearInput(): FormControl {
    return this.inputForm.get('vehicleStartYearInput') as FormControl;
  }

  get vehicleEndYearInput(): FormControl {
    return this.inputForm.get('vehicleEndYearInput') as FormControl;
  }

  get vehicleModelInput(): FormArray {
    return this.inputForm.get('vehicleModelInput') as FormArray;
  }

  get colorFormArray(): FormArray {
    return this.inputForm.get('colorFormArray') as FormArray;
  }

  get vehicleBodyTypeInput(): FormArray {
    return this.inputForm.get('vehicleBodyTypeInput') as FormArray;
  }

  get vehiclePlateInput(): FormControl {
    return this.inputForm.get('vehiclePlateInput') as FormControl;
  }

  get municipalityNumberInput(): FormControl {
    return this.inputForm.get('municipalityNumberInput') as FormControl;
  }

  get damageAreaFormArray(): FormArray {
    return this.inputForm.get('damageAreaFormArray') as FormArray;
  }

  get collisionStartDateInput(): FormControl {
    return this.inputForm.get('collisionStartDateInput') as FormControl;
  }

  get collisionEndDateInput(): FormControl {
    return this.inputForm.get('collisionEndDateInput') as FormControl;
  }

  checkInvalid(control: string): void {
    checkInvalidAndRemoveFromErrors(this.inputForm, control, this.listOfErrors);
    if (this.listOfErrors.length <= 0) {
      this.showAlert = false;
    }
  };

  submit() {
    this.loading = true;
    this.inputForm.markAllAsTouched();

    if (this.inputForm.invalid) {
      this.showAlert = true;
      getFormValidationErrors(this.inputForm, this.listOfErrors);
      this.loading = false;
      scrollToAlertPanel();
    }
    else {
      // We must display the enums values but send the enums keys to server
      const selectedColors = this.colorFormArray.controls
        .map((checked, i) => checked.value ? Object.keys(ColorEnum)[i] : null)
        .filter(v => v != null);

      const selectedImpactAreas = this.damageAreaFormArray.controls
        .map((checked, i) => checked.value ? Object.keys(DamageAreaEnum)[i] : null)
        .filter(v => v != null);

      Object.assign(
        this.itemToSubmit,
        {
          freedomOfInformation: this.radioButtons.value,
          occurrenceNumber: this.fileNumberInput.value,
          dateOfIncident: this.incidentDateInput.value,
          vehicleMake: this.vehicleMakeInput.value.filter((value: string) => value.trim() !== ""),
          vehicleModelYearStart: this.vehicleStartYearInput.value,
          vehicleModelYearEnd: this.vehicleEndYearInput.value,
          vehicleModel: this.vehicleModelInput.value.filter((value: string) => value.trim() !== ""),
          vehicleColor: selectedColors,
          vehicleBodyStyle: this.vehicleBodyTypeInput.value.filter((value: string) => value.trim() !== ""),
          vehiclePlateNumber: this.vehiclePlateInput.value?.trim() === "" ? null : this.vehiclePlateInput.value,
          municipalityNameNumber: this.municipalityNumberInput.value,
          impactPoint: selectedImpactAreas,
          collisionDateRangeStart: this.collisionStartDateInput.value,
          collisionDateRangeEnd: this.collisionEndDateInput.value
        });

      const param: Item = this.itemToSubmit as Item;
      this.requestSub = this.submitService.submitForm(param)
        .subscribe({
          next: () => {
            this.showAlert = false;
            this.showSuccess = true;
            this.loading = false;
            scrollToAlertPanel();
          },
          error: (err) => {
            this.showSuccess = false;
            this.showAlert = true;
            this.loading = false;
            scrollToAlertPanel();
            console.error(`Something went wrong, the following is the received error: ${err}`);
          },
          complete: () => {
            console.log("Complete Submission");
          }
        });
    };

  }
}