import { Injectable } from '@angular/core';
import { ProfileType } from '../models/Profile';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  profile$ = new BehaviorSubject<ProfileType>({
    userPrincipalName: ""
  });

  constructor(private httpClient: HttpClient) { }

  getProfile() {
    return this.httpClient.get<ProfileType>(environment.apiConfig.graph.uri);
  }

  checkEmailExists(userPrincipalName: string) {
    return this.httpClient.get(`${environment.apiConfig.server.APIEndpoint}/rpd/getUser?requestor=${encodeURIComponent(userPrincipalName)}`);
  }

  availability() {
    return this.httpClient.get(`${environment.apiConfig.server.APIEndpoint}/availability?officeId=${environment.officeHourType}`, { responseType: 'text' });
  }
}
