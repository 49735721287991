import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorNamePipe } from "../../Pipes/error-name.pipe";
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-alert-panel',
  standalone: true,
  imports: [ErrorNamePipe, NgClass],
  templateUrl: './alert-panel.component.html',
  styleUrl: './alert-panel.component.css'
})
export class AlertPanelComponent {
  @Input() alertErrorList!: string[];
  @Input() alertType!: string;
  @Input() alertTitle!: string;
  @Input() alertMessage!: string;
  @Output() scrollToValidation = new EventEmitter<string>();

  alertIcon!: string;

  setupAlert() {
    let baseAlertCss = 'ontario-alert ontario-alert--';

    switch (this.alertType) {
      case 'informational':
      case 'information':
        baseAlertCss += 'informational';
        this.alertIcon = '/assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-alert-information';
        break;
      case 'warning':
      case 'sessionWarning':
      case 'cancel':
        baseAlertCss += 'warning';
        this.alertIcon = '/assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-alert-warning';
        break;
      case 'error':
      case 'apiError':
      case 'authError':
        baseAlertCss += 'error';
        this.alertIcon = '/assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-alert-error';
        break;
      case 'success':
        baseAlertCss += 'success';
        this.alertIcon = '/assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-alert-success';
        break;
      default:
        console.log('alert type does not exist');
    }

    return baseAlertCss;
  }


  navigateToValidation(error: string) {
    this.scrollToValidation.emit(error);
  }

  onRowKeyDown(event: KeyboardEvent, error: any) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.navigateToValidation(error);
      event.preventDefault();
    }
  }
}
