<body>
    <div class='documentation-only--application'>
        <div class='ontario-header__container'>
            <header class='ontario-application-header ontario-header ' id='ontario-header'>
                <div class='ontario-row'>
                    <div class='ontario-columns ontario-small-6 ontario-application-header__logo'
                        style="margin-right: auto;">
                        <a tabindex="-1" class="logo" href='https://www.ontario.ca/page/government-ontario'>
                            <img src='../assets/ontario-design-system/logos/ontario-logo--desktop.svg'
                                alt='Ontario.ca homepage' role='img' />
                        </a>
                    </div>
                </div>
            </header>
            <div class='ontario-application-subheader-menu__container subheader'>
                <section class='ontario-application-subheader'>
                    <div class='ontario-row'>
                        <div class='ontario-columns ontario-small-12 ontario-application-subheader__container'>
                            <h1 style="color: #fff;" class='ontario-application-subheader__heading'>
                                {{subTitle}}
                            </h1>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div class='ontario-overlay'></div>
    </div>
    @if(loading){
    <app-loading-spinner [fullScreen]="true"></app-loading-spinner>
    }
    @else if(brickTheApp || brickWithWarning) {
    <div class='ontario-row'>
        <div class='ontario-columns ontario-small-12'>
            <app-alert-panel id="alertPanel" [alertTitle]="'Form unavailable'" [alertMessage]=errorMessage
                [alertType]="brickTheApp ? 'error' : 'warning'"></app-alert-panel>
        </div>
    </div>
    }@else{
    <router-outlet />
    }

    <footer class='ontario-footer ontario-footer--default footer' style="margin-top: auto;">
        <div class='ontario-row'>
            <div class='ontario-columns ontario-small-12'>
                <ul class='ontario-footer__links-container ontario-footer__links-container--inline'>
                    <li><a class='ontario-footer__link'
                            href='https://www.ontario.ca/page/accessibility'>Accessibility</a></li>
                    <li><a class='ontario-footer__link' href='https://www.ontario.ca/page/privacy-statement'>Privacy</a>
                    </li>

                </ul>
                <div class='ontario-footer__copyright'>
                    <a class='ontario-footer__link' href='https://www.ontario.ca/page/copyright-information'>&copy;
                        King's Printer for Ontario,
                        <span class='ontario-nbsp'>2012&ndash;24</span></a>
                </div>
            </div>
        </div>
    </footer>
</body>